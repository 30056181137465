.grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
	grid-gap: 20px;
	align-items: stretch;
}
  
.grid > article {
	box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
	color: white;
	background-color: #20202A;
}
  
.grid > article img {
	width: 100%;
}
  
.grid .text {
	padding: 20px;
}

.container {
	margin-top: 100px;
}